import React, { useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import $ from "jquery";
// import TermsConditions from '../pages/termsConditions';
// import PrivacyPolicy from '../pages/privacyPolicy';
import Header from "../components/header";
import Footer from "../components/footer";
import Home from "../pages/index";
import ScrollToTop from "react-scroll-to-top";

import DeleteWallet from "../pages/delete-wallet";
import Contact from "../pages/contact";
import Privacy from "../pages/privacy-policy";
import Terms from "../pages/terms-conditions";
import Thankyou from "../pages/request-received";
import Walletdownload from "../pages/wallet-download";
import Privacyios from "../pages/privacy-policy-ios";
import Privacyandroid from "../pages/privacy-policy-android";
const AppRouter = () => {
  return (
    <BrowserRouter>
      <div className="App">
        <Header />
        <ScrollToTop smooth color="#0F9D5C; " />
        {/* <h1>Hello!</h1>
      <h2>Scroll down for the button to appear</h2>
      <p style={{ marginTop: "150vh" }}>bottom</p> */}
      </div>
      <div className="Content">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route element={<Contact />} path="/contact" exact="true" />
          <Route element={<DeleteWallet />} path="/delete" exact="true" />
          <Route element={<Privacy />} path="/privacy-policy" exact="true" />
          <Route element={<Terms />} path="/terms-conditions" exact="true" />
          <Route element={<Thankyou />} path="/request-received" exact="true" />
          {/* <Route element={<Thankyou />} path="/request-received" exact="true" /> */}
          <Route
            element={<Walletdownload />}
            path="/wallet-download"
            exact="true"
          />
          <Route
            element={<Privacyios />}
            path="/privacy-policy-ios"
            exact="true"
          />
          <Route
            element={<Privacyandroid />}
            path="/privacy-policy-android"
            exact="true"
          />
        </Routes>
      </div>
      <Footer />
    </BrowserRouter>
  );
};

export default AppRouter;
