import React, { useEffect, useState } from "react";
import axios from "axios";
import { getPlatform } from "react-use-platform";

const VideoPlayer = () => {
  const [url, setUrl] = useState("");
  let platform = getPlatform();
  let apiUrl = "";
  let downloadTxt = "Download Wallet";
  let imageSrc = "";
  let videoUrl = "";
  if (platform == "windows") {
    videoUrl = "../img/download_wallet_window.mp4";
  }
  if (platform == "mac") {
    videoUrl = "../img/download_wallet_ios.mp4";
  }

  if (platform == "android") {
    downloadTxt = "Coming Soon";
    imageSrc = "img/android_mobile.svg";
  }

  if (platform == "ios") {
    downloadTxt = "Coming Soon";
    imageSrc = "img/ios_mobile.svg";
  }
  if (platform == "unknown") {
    downloadTxt = "Coming Soon";
    imageSrc = "/img/window.svg";
  }
  return (
    <>
      {platform == "mac" ? (
        <iframe
          width="580"
          height="315"
          src="https://www.youtube.com/embed/UBpzx_F8oy0?si=9vyYxmYu1XY5qxoH"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      ) : (
        <div id="myvideo1" width="100%" height="100%" controls>
          <iframe
            width="680"
            height="315"
            src="https://www.youtube.com/embed/yCiMy0CQcEs?si=jDWEI3irk6fsz509"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>
      )}
    </>
  );
};

export default VideoPlayer;
