import React from "react";
import { useEffect } from "react";
import AOS from "aos";
import { Helmet } from "react-helmet";
import "../../src/style-lg.css";

import "aos/dist/aos.css";
import DownloadButton from "../components/downloadButton";
import VideoPlayer from "../components/videoPlayer";
function Home() {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <Helmet>
        <title>
          CIFDAQ Wallet | Manage and Store Digital Assets Securely Using MPC
          Technology
        </title>
        <meta name="author" content="cifdaqwallet.com" />
        <meta
          name="Description"
          content=" Store and manage digital assets securely with CIFDAQ Wallet, leveraging advanced MPC technology, ensuring ultimate protection and convenience"
        />

        <meta
          name="keywords"
          content="CIFDAQ Wallet, cryptocurrency wallet, Crypto wallet, , digital wallet, best crypto wallet, safest crypto wallet"
        />

        <meta name="format-detection" content="telephone=no" />

        <meta
          property="og:title"
          content=" CIFDAQ Wallet | Manage and Store Digital Assets Securely Using MPC Technology"
        />

        <meta
          property="og:description"
          content=" Store and manage digital assets securely with CIFDAQ Wallet, leveraging advanced MPC technology, ensuring ultimate protection and convenience
    "
        />

        <meta property="og:type" content="website" />
        <meta
          property="og:site_name"
          content="CIFDAQ Wallet | Manage and Store Digital Assets Securely Using MPC Technology"
        />

        <meta property="og:url" content="http://cifdaqwallet.com" />

        <meta property="og:image" content="https://cifdaqwallet.com" />
        <meta property="og:image:alt" content="Visit cifdaqwallet.com" />
        <meta name="twitter:card" content="summary" />

        <meta name="twitter:title" content=" CIFDAQ Wallet" />

        <meta
          property="twitter:description"
          content=" CIFDAQ Wallet leverages the power of Multi-Party Computation (MPC) technology."
        />

        <meta name="twitter:site" content="@CIFDAQWallet" />
        <meta
          property="og:image"
          content="https://cifdaqwallet.com/img/meta_lg.png"
        />
        <meta
          property="og:image:url"
          content="https://cifdaqwallet.com/img/meta_lg.png"
        />
        <meta property=" og:image:width" content="1200" />
        <meta property="og:image:height" content="627" />
      </Helmet>

      <div className="testb">
        <p></p>
      </div>
      <section className="hero-banner">
        <div className="inner-hero-banner">
          <div className="main-container">
            <div className="inner-row">
              <div className="flx_bx">
                <div className="left_block">
                  <h1>
                    <span> CIFDAQ </span> Wallet Powered <br />
                    By<span> MPC Technology </span>
                  </h1>
                  <ul className="bg_one_dt">
                    <li>
                      Regulated <span></span>
                    </li>
                    <li>
                      Compliant <span></span>
                    </li>
                    <li>Transparent </li>
                    {/* <li>
                      Secured <span></span>
                    </li>
                    <li>
                      Scalable <span></span>
                    </li>
                    <li>Decentralised </li> */}
                  </ul>

                  <ul className="pd-tp-rm">
                    <li>
                      Secured <span></span>
                    </li>
                    <li>
                      Scalable <span></span>
                    </li>
                    <li>Decentralised </li>
                  </ul>
                  {<DownloadButton />}
                </div>
                <div className="right_block">
                  {/* <img src="img/hero-image.png" alt="" /> */}

                  <VideoPlayer />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- second block start --> */}
      <section className="second_block">
        <div className="main-container">
          <div className="inner-row">
            <div data-aos="fade-up" data-aos-duration="600">
              <h2>Not Your Key, Not Your Crypto</h2>
              <p style={{ color: "#f6f6f6b0" }}>
                The essence of cryptocurrency is decentralization, and at the
                heart of this is the principle of "Not Your Key, Not Your
                Crypto". This means that if you don't control the private keys
                to your crypto assets, you don't truly own them. At CIFDAQ, we
                take this principle seriously. <br />
                Our CIFDAQ Wallet is designed to give you full control over your
                private keys, ensuring that they are never shared with anyone,
                not even us. We believe that your crypto assets should be in
                your hands, and your hands alone. This is why we've developed
                advanced security features that protect your private keys from
                both internal and external threats. <br />
                <br />
                Remember, in the world of crypto, knowledge is power.
                Understanding the importance of private keys and how to protect
                them is <br />
                the first step toward true crypto ownership.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- second block end -->
    <!-- mpc block  start --> */}
      <section className="mpc_wallet">
        <div className="main-container">
          <div className="inner-row">
            <h2 data-aos="fade-up" data-aos-duration="600">
              Powered by MPC Technology
            </h2>
            <p
              className="mpc-dt"
              data-aos="fade-up"
              data-aos-duration="600"
              style={{ color: "#f6f6f6b0" }}
            >
              Our CIFDAQ Wallet leverages the power of Multi-Party Computation
              (MPC) technology. This advanced technology allows us to provide a
              secure and efficient platform for our users. Here's how:
            </p>

            <div className="inner-grid">
              <div
                className="mpc-block"
                data-aos="fade-right"
                data-aos-duration="600"
              >
                <img src="img/curly_braces.svg" className="braces" alt="" />
                <div className="cntent-block">
                  <h3>Machine Learning</h3>
                  <p>
                    Our MPC technology implements machine learning techniques
                    that understand and analyze code across various programming
                    languages. This not only enhances the efficiency of our
                    platform but also provides bug detection and quality
                    assurance capabilities. By identifying potential
                    vulnerabilities in smart contracts deployed on the CIFDAQ
                    chain, we can rectify them before they become a security
                    risk. This proactive approach to security ensures that our
                    user's assets are always protected.
                  </p>
                </div>
              </div>
              <div
                className="mpc-block ai_securet"
                data-aos="fade-left"
                data-aos-duration="600"
              >
                <img src="img/security-1.svg" alt="" className="" />
                <div className="cntent-block">
                  <h3>AI-Enhanced Security</h3>
                  <p>
                    At CIFDAQ, we use AI technology to enhance the security of
                    the exchange and wallets. This AI technology works hand in
                    hand with our MPC technology to ensure the security of
                    investor's and user's funds. Our MPC wallets are powered by
                    AI predictive models that analyze patterns and behaviors to
                    predict and prevent potential security threats. This creates
                    a robust shield of protection from external hacking and
                    fraud, giving our users peace of mind.
                  </p>
                </div>
              </div>
              <div
                className="mpc-block"
                data-aos="fade-right"
                data-aos-duration="600"
              >
                <img src="img/key.svg" alt="" className="key_img" />
                <div className="cntent-block">
                  <h3>Fractionalized Private Keys </h3>
                  <p>
                    One of the unique features of our MPC technology is the
                    fractionalization of private keys. The private key of each
                    customer's hot wallet is split into multiple pieces and
                    stored in anonymous locations by our AI system. This ensures
                    that no internal developer or managerial employee will know
                    where the private key is stored. By doing this, we add an
                    additional layer of security that protects our user's assets
                    from both internal and external threats.
                  </p>
                </div>
              </div>
              <div
                className="mpc-block"
                data-aos="fade-left"
                data-aos-duration="600"
              >
                <img src="img/bulb.svg" alt="" className="bulb_icon" />
                <div className="cntent-block">
                  <h3>AI MPC Model</h3>
                  <p>
                    Our AI technology creates a new MPC model called "AI MPC"
                    that further enhances the security of user's private keys.
                    This model uses AI algorithms to analyze and predict
                    potential internal fraud, corruption, and external hacking
                    threats. By doing this, we can take proactive measures to
                    prevent these threats, further enhancing the security of our
                    user's private keys.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- mpc block  end -->

    <!-- feature block start --> */}
      <section className="feature_wallet">
        {console.log(process.env.REACT_APP_CONTACT_US_API)}
        <div className="main-container">
          <div className="inner-row">
            <h4 data-aos="fade-up" data-aos-duration="600">
              Features
            </h4>
            <p
              className="feature_wallet"
              data-aos="fade-up"
              data-aos-duration="600"
            >
              The CIFDAQ Wallet is more than just a wallet. It's a gateway to
              the CIFDAQ blockchain ecosystem. Here are some of <br />
              the features you can enjoy:
            </p>
            <div className="inner-grid">
              <div
                className="mpc-block bg_img"
                data-aos="fade-right"
                data-aos-duration="600"
              >
                <img src="img/bag.svg" className="bag_img" alt="" />
                <div className="cntent-block">
                  <h3>Buy, Sell, and Swap</h3>
                  <p className="height-block1">
                    With CIFDAQ Wallet, you can easily buy, sell, and swap your
                    crypto assets within the wallet interface.
                  </p>
                </div>
              </div>
              <div
                className="mpc-block gap_eight"
                data-aos="fade-left"
                data-aos-duration="600"
              >
                <img src="img/link.svg" alt="" className="link_img" />
                <div className="cntent-block">
                  <h3>Multi-Product Compatibility </h3>
                  <p className="height-block1">
                    The CIFDAQ Wallet is compatible with multiple products
                    linked to the CIFDAQ blockchain ecosystem, including
                    Metaverse, centralized exchange, decentralized exchange, NFT
                    marketplace, and CIFDAQ multi-functional launchpad.
                  </p>
                </div>
              </div>
              <div
                className="mpc-block"
                data-aos="fade-right"
                data-aos-duration="600"
              >
                <img src="img/securt.svg" alt="" className="def_icon" />
                <div className="cntent-block">
                  <h3>Access to DeFi</h3>
                  <p className="height-block2">
                    With support for decentralized applications (dApps), CIFDAQ
                    Wallet gives you access to the exciting world of
                    Decentralized Finance (DeFi), opening up opportunities for
                    yield farming, liquidity mining, and more.
                  </p>
                </div>
              </div>
              <div
                className="mpc-block"
                data-aos="fade-left"
                data-aos-duration="600"
              >
                <img src="img/nft.svg" alt="" className="nft_img" />
                <div className="cntent-block">
                  <h3>NFT Support</h3>
                  <p className="height-block2">
                    CIFDAQ Wallet supports Non-Fungible Tokens (NFTs), allowing
                    you to store, view, and manage your digital art and other
                    NFTs in a secure environment.
                  </p>
                </div>
              </div>
              <div
                className="mpc-block "
                data-aos="fade-right"
                data-aos-duration="600"
              >
                <img src="img/security-1.svg" alt="" className="secur_feat" />
                <div className="cntent-block">
                  <h3>Secure Transactions</h3>
                  <p className="height-block3">
                    With our advanced security features, you can make
                    transactions with peace of mind knowing that your assets are
                    protected.
                  </p>
                </div>
              </div>
              <div
                className="mpc-block"
                data-aos="fade-left"
                data-aos-duration="600"
              >
                <img src="img/plus.svg" alt="" className="plus_icon" />
                <div className="cntent-block">
                  <h3>Easy Access</h3>
                  <p className="height-block3">
                    Access your assets anytime, anywhere with our user-friendly
                    interface.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- feature block end -->

    <!-- accrdion block started --> */}
      <section className="accrdion_block">
        <div className="main-container">
          <div className="inner-row">
            <h5 data-aos="fade-up" data-aos-duration="600">
              Frequently asked questions
            </h5>
            <span
              className="span_accrdion"
              data-aos="fade-up"
              data-aos-duration="600"
            >
              Everything you need to know about CIFDAQ wallet
            </span>

            <div
              className="accordion_container "
              data-aos="fade-up"
              data-aos-duration="700"
            >
              <div className="accrdion_blck">
                <div className="accordion_head">
                  What is the CIFDAQ Wallet ?
                  <span className="plusminus">+</span>
                </div>
                <div className="accordion_body" style={{ display: "none" }}>
                  <p>
                    The Cifdaq Wallet is a secure and user-friendly crypto
                    wallet that allows you to store, send, and receive
                    cryptocurrencies. It's powered by advanced MPC technology
                    and AI to provide top-notch security.
                  </p>
                </div>
              </div>

              <div className="accrdion_blck">
                <div className="accordion_head">
                  How secure is the CIFDAQ Wallet ?
                  <span className="plusminus">+</span>
                </div>
                <div className="accordion_body" style={{ display: "none" }}>
                  <p>
                    The Cifdaq Wallet is highly secure. It uses MPC technology
                    and AI to enhance security, including fractionalizing
                    private keys and storing them in anonymous locations.
                  </p>
                </div>
              </div>

              <div className="accrdion_blck">
                <div className="accordion_head">
                  What cryptocurrencies can I store in the Cifdaq Wallet?
                  <span className="plusminus">+</span>
                </div>
                <div className="accordion_body" style={{ display: "none" }}>
                  <p>
                    The Cifdaq Wallet supports all cryptocurrencies available in
                    the CIFDAQ blockchain ecosystem.
                  </p>
                </div>
              </div>
              <div className="accrdion_blck">
                <div className="accordion_head">
                  How can I reach customer support ?
                  <span className="plusminus">+</span>
                </div>
                <div className="accordion_body" style={{ display: "none" }}>
                  <p>
                    You can reach our customer support team by emailing &nbsp;
                    <a href="mailto:customersupport@cifdaqwallet.com">
                      customersupport@cifdaqwallet.com
                    </a>
                  </p>
                </div>
              </div>
              <div className="accrdion_blck">
                <div className="accordion_head">
                  What is MPC technology ?<span className="plusminus">+</span>
                </div>
                <div className="accordion_body" style={{ display: "none" }}>
                  <p>
                    MPC, or Multi-Party Computation, is a technology that allows
                    multiple parties to jointly compute a function over their
                    inputs while keeping those inputs private.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Home;
