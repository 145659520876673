import React from "react";
import { useEffect } from "react";
import "../style-lg.css";

function Privacyandroid() {
  return (
    <>
      <section class="privacy_page">
        <div class="main-container">
          <div class="inner-row">
            <div class="inner-block">
              <h1>Terms of Use</h1>
              <strong>
                PLEASE READ THESE TERMS OF USE CAREFULLY. BY ACCESSING OR USING
                THE SERVICES OR THE SITE, YOU AGREE THAT YOU HAVE READ,
                UNDERSTOOD, AND AGREE TO BE BOUND BY THESE TERMS OF USE,
                INCLUDING THE BINDING ARBITRATION AGREEMENT BELOW. IF YOU DO NOT
                AGREE, PLEASE DO NOT USE THE SERVICES OR SITE.
              </strong>

              <p>
                We recognise our responsibilities in relation to the collection,
                holding/storing, processing, use, disclosing and/or transfer of
                personal data (to the extent we collect any). Your privacy is of
                utmost importance to us.
                <br /> <br />
                This policy (the <b>“Policy”</b>) outlines how we collect,
                process, use, store and disclose your personal data (to the
                extent we collect any at all). Please take a moment to read
                about how we collect, process, use, store and/or disclose your
                personal data so that you know and understand the purposes for
                which we may collect, process, use, store and/or disclose your
                personal data. This Policy applies only to the CIFDAQ Wallet
                (the <b>“Wallet”</b>) and is in conjunction with the Terms of
                Use available on the Website. By accessing this Wallet, you
                agree and consent to this Privacy Policy.
                <br /> <br />
                This Policy supplements but does not supersede nor replace any
                other consent which you may have previously provided to us, nor
                does it affect any rights that we may have at law in connection
                with the collection, processing, use, storing and/or disclosure
                of your personal data. We may from time to time update this
                Policy to ensure that this Policy is consistent with our future
                developments, industry trends and/or any changes in legal or
                regulatory requirements. Subject to your rights at law, the
                prevailing terms of this Policy shall apply. For the avoidance
                of doubt, this Policy forms part of the terms and conditions
                governing your relationship with us and should be read in
                conjunction with such terms and conditions. This Policy is in
                conjunction with the Privacy Policy available on our Website.
                <br />
                <br />
                The security of your personal data is important to us. At each
                stage of data collection, processing, use, storing and
                disclosure, CIFDAQ has in place physical, electronic,
                administrative, and procedural safeguards to protect the
                personal data stored with us. However, do note that no
                transmission of personal data over the internet can be
                guaranteed to be 100% secure – accordingly and despite our
                efforts, CIFDAQ cannot guarantee or warrant the security of any
                information you transmit to us, or to or from our online
                services. CIFDAQ shall not have any responsibility or liability
                for the security of information transmitted via the internet.
                <br />
                <br />
                This Policy describes how CIFDAQ may collect, process, use,
                store, disclose, process, and manage your personal data (to the
                extent we collect any), and applies to any individual’s personal
                data which is in our possession or under our control.
              </p>
              <br />
              <br />
              <h2>1. Personal Data Collected and shared By CIFDAQ</h2>
              <p>
                CIFDAQ collects your email ID and phone number. The email ID is
                linked to your wallet and can be used to recover your Wallet
                keys later. <br />
                <br />
                Apart from this, CIFDAQ does not collect any personal data. It
                uses no third - party analytics or advertising frameworks.
                CIFDAQ logs no other information on you and all your data is
                stored on your personal device, including pars of your wallet
                key. <br />
              </p>
              <br />
              <br />
              <h2>
                2. Disclosure And Collection Of Personal Data in Compliance with
                Law{" "}
              </h2>
              <p>
                We may from time to time and in compliance with all applicable
                laws, rules, regulations and legal orders, share your personal
                data with a personnel of CIFDAQ, group entities, or to third
                parties (including without limitation banks, financial
                institutions, credit card companies, credit bureaus and their
                respective service providers, companies providing services
                relating to insurance and/or reinsurance to us, and associations
                of insurance companies, agents, contractors or third party
                service providers who provide services to us such as
                telecommunications, information technology, payment, data
                processing, storage and archival, and our professional advisers
                such as our auditors and lawyers, and regulators and
                authorities), located in any jurisdiction.. Please be assured
                that when we collect or disclose your personal data to such
                parties, we will disclose only the information that is necessary
                to comply with such laws, rules, regulations, or legal orders
                and will also require them to ensure that any personal data
                disclosed to them are kept confidential and secure. <br />
                <br />
                We wish to emphasise that CIFDAQ does not sell personal data to
                any third parties, and we shall remain fully compliant of any
                duty or obligation of confidentiality imposed on us under the
                applicable agreement(s) and/or terms and conditions that govern
                our relationship with you or our customer or any applicable law.{" "}
                <br />
                <br />
                You are responsible for ensuring that the personal data you
                provide to us is accurate, complete, and not misleading and that
                such personal data is kept up to date. You acknowledge that
                failure on your part to do so may result in our inability to
                provide you with the products and services you have requested.
                To update your personal data, please contact us (please see
                Section 9 below for contact details). Where you provide us with
                personal data concerning individuals other than yourself, you
                are responsible for obtaining all legally required consents from
                the concerned individuals and you shall retain proof of such
                consent(s), such proof to be provided to us upon our request.{" "}
                <br />
                <br />
                We may transfer, store, process, use and/or deal with your data
                in any jurisdiction, and accordingly such personal data may be
                transferred to computers, servers or hardware located outside of
                your state, province, country, or other governmental
                jurisdiction where the data protection laws may differ from
                those in your jurisdiction. CIFDAQ will take all steps
                reasonably necessary to ensure that your data is treated
                securely and in accordance with this Policy and no transfer of
                your personal data will take place to an organisation or a
                country unless there are adequate controls in place including
                the security of your data and other personal information
                (including without limitation the Standard Contractual Clauses
                approved by the European Commission). Your consent to this
                Policy followed by your submission of such information
                represents your agreement to the transfer of personal data as
                described herein.
              </p>
              <br />
              <br />
              <h2>3. Other Websites</h2>
              <p>
                Our Product may contain links to other websites and products
                which may or may not be maintained by CIFDAQ.{" "}
                <b>
                  This Policy only applies to CIFDAQ Wallet and does not extend
                  to any other products, sites (including sub domains), services
                  by CIFDAQ
                </b>
                . Each of CIFDAQ’s products, sites (including sub domains),
                services may collect different data and have a different Privacy
                Policy. <br />
                <br />
                When visiting these sites (whether third-party or maintained by
                CIFDAQ), please read their privacy policies which will apply to
                your use of those websites.
              </p>
              <br />
              <br />
              <h2>4. Retention Of Personal Data</h2>
              <p>
                Your data is retained for as long as the purpose for which it
                was collected remains and until it is no longer necessary for
                any legal or business purposes. This enables us to comply with
                legal and regulatory requirements or use it where we need to for
                our legitimate purposes. We may need to retain information for a
                longer period where we need the information to comply with
                regulatory or legal requirements or where we may need it for our
                legitimate purposes (e.g., to help us respond to queries or
                complaints, fighting fraud and financial crime, responding to
                requests from regulators etc).
                <br />
                <br />
                When we no longer need to use personal information, we will
                remove it from our systems and records and/or take steps to
                anonymise it so that you can no longer be identified from it.
              </p>
              <br />
              <br />

              <h2>5. Queries, Access and Withdrawal of Consent and Data</h2>
              <p>
                If you:
                <br />
                <br />
                (a) Have queries about our data protection processes and
                practices;
                <br />
                <br />
                (b) Wish to request access to and/or make corrections to your
                personal data in our possession or under our control; or
                <br />
                <br />
                (c) Wish to remove your wallet or account with us
                <br />
                <br />
                please submit a written request (with supporting documents, (if
                any) to our legal personnel at: {""}
                <a href="mailto:legal@cifdaqwallet.com">
                  legal@cifdaqwallet.com
                </a>{" "}
                {""}
                Our Legal Personnel shall endeavour to respond to you within 30
                days of your submission. Please note that if you withdraw your
                consent to any or all use or disclosure of your personal data,
                depending on the nature of your request, we may not be in a
                position to continue to provide our services or products to you
                or administer any contractual relationship in place. Such
                withdrawal may also result in the termination of any agreement
                you may have with us. Our legal rights and remedies are
                expressly reserved in such an event.
                <br />
                <br />
                Please also note that we may be constrained by technical
                limitations in order to meet your requests and shall endeavour
                to fulfil them to the best of our abilities. <br />
                <br />
                We may charge you a fee for processing your request for access.
                Such a fee depends on the nature and complexity of your access
                request. Information on the processing fee will be made
                available to you upon request.
              </p>
              <br />
              <br />

              <h2>6. Contact Information</h2>
              <p>
                To contact us on any aspect of this Policy or your personal data
                or to provide any feedback that you may have, please contact our
                Legal Team at {""}{" "}
                <a href="mailto:legal@cifdaqwallet.com">
                  legal@cifdaqwallet.com
                </a>
              </p>
              <br />
              <br />

              <h2>7. Governing Law and Jurisdiction</h2>
              <p>
                This Policy and your use of the Product shall be governed and
                construed in accordance with the laws of Delaware, United
                States.
              </p>
              <br />
              <br />

              <h2>8. Amendments And Updates To CIFDAQ Privacy Policy</h2>
              <p>
                We reserve the right to amend this Policy from time to time to
                ensure that this Policy is consistent with any developments to
                the way CIFDAQ uses your personal data or any changes to the
                laws and regulations applicable to CIFDAQ. We will make
                available the updated Policy on the CIFDAQ Website. You are
                encouraged to visit the CIFDAQ Website from time to time to
                ensure that you are well informed of our latest policies in
                relation to personal data protection. All communications,
                transactions, and dealings with us shall be subject to the
                latest version of this Policy in force at the time.
              </p>
              <br />
              <br />
              <h2>9. For Residents Belonging to Special Jurisdictions</h2>
              <p>
                For special rights applicable under certain legislations to
                specific individuals, please refer to the Privacy Policy
                available on our Website.
              </p>
              <br />
              <br />
              <h2>10. Your Acceptance Of These Terms</h2>
              <p>
                This Policy applies in conjunction with any other notices,
                contractual clauses and consent clauses that apply in relation
                to the collection, processing, use, storing and disclosure of
                your personal data by us. We may revise this Policy from time to
                time without any prior notice. You may determine if any such
                revision has taken place by referring to the date on which this
                Policy was last updated.
                <br />
                <br />
                By using the Product, website and/or any services provided by
                CIFDAQ, you signify your acceptance of this Policy and terms of
                service. If you do not agree to this Policy or terms of service,
                please do not use the Product, Website or any services provided
                by CIFDAQ. Your continued use of the Product following the
                posting of changes to this Policy will be deemed your acceptance
                of those changes.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Privacyandroid;
