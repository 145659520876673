import React, { useEffect, useState } from "react";
import axios from 'axios';
import { getPlatform } from 'react-use-platform'
import "../style-lg.css";
function Walletdownload() {
  const [url, setUrl] = useState('');
  const [macUrl, setMacUrl] = useState('');
  const [winUrl, setWinUrl] = useState('');

  let platform = getPlatform();
  let apiUrl = '';
  let macApiUrl = "";
  let winApiUrl = "";
  let downloadTxt = 'Download Wallet';
  let imageSrc = '';
  let videoUrl = '';

  if (platform == 'windows') {
    apiUrl = 'https://api.github.com/repos/Shrisaya/win-cifdaq-wallet/releases/latest';
    imageSrc = "img/window.svg";
    videoUrl = "../img/download_wallet_window.mp4";
    const getResult = async () => {
      try {
        const response = await axios.get(apiUrl);
        setUrl(response.data.assets[0].browser_download_url);
        setWinUrl(response.data.assets[0].browser_download_url);
        //setIsLoading(false);
      } catch (error) {
        console.log(error);
      } finally {

      }
    }
    getResult();

    macApiUrl = 'https://api.github.com/repos/Shrisaya/mac-cifdaq-wallet/releases/latest';
    const getMacResult = async () => {
      try {
        const response = await axios.get(apiUrl);
        setMacUrl(response.data.assets[2].browser_download_url);

      } catch (error) {
        console.log(error);
      } finally {

      }
    }
    getMacResult();
  }
  if (platform == 'mac') {
    apiUrl = 'https://api.github.com/repos/Shrisaya/mac-cifdaq-wallet/releases/latest';
    imageSrc = "img/mac.svg";
    videoUrl = "../img/download_wallet_ios.mp4";
    const getResult = async () => {
      try {
        const response = await axios.get(apiUrl);
        setUrl(response.data.assets[2].browser_download_url);
        setMacUrl(response.data.assets[0].browser_download_url);
      } catch (error) {
        console.log(error);
      } finally {

      }
    }
    getResult();

    winApiUrl = 'https://api.github.com/repos/Shrisaya/mac-cifdaq-wallet/releases/latest';
    const getWinResult = async () => {
      try {
        const response = await axios.get(apiUrl);
        setWinUrl(response.data.assets[2].browser_download_url);

      } catch (error) {
        console.log(error);
      } finally {

      }
    }
    getWinResult();
  }

  if (platform == 'android') {
    downloadTxt = 'Coming Soon';
    imageSrc = "img/android_mobile.svg";
  }

  if (platform == 'ios') {
    downloadTxt = 'Coming Soon';
    imageSrc = "img/ios_mobile.svg";
  }
  if (platform == 'unknown') {
    downloadTxt = 'Coming Soon';
    imageSrc = "/img/window.svg";
  }
  //console.log(platform);



  return (
    <>
      <section className="wallet-download">
        <div className="main-container">
          <div className="inner-row">
            <div className="grid-block">
              <div className="left-block">
                <div className="block-one">
                  <h1>Download Desktop App</h1>
                  <p>
                    The essence of cryptocurrency is decentralization, and at
                    the heart of this is the principle of "Not Your Key, Not
                    Your Crypto". This means that if you don't control the
                    private keys to your crypto assets, you don't truly own
                    them.
                  </p>
                  <span>
                    Instruction on how to download and install desktop app
                  </span>
                  <div className="video_playbtn" data-bs-toggle="modal" data-bs-target="#exampleModal">
                    <div className="play_btn">
                      <p>
                        Play video
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="28"
                          height="28"
                          viewBox="0 0 28 28"
                          fill="none"
                        >
                          <circle cx="14" cy="14" r="14" fill="white" />
                          <path
                            d="M20 14.5L10.25 20.1292L10.25 8.87083L20 14.5Z"
                            fill="#0FA25E"
                          />
                        </svg>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="right-block">
                <div>
                  <img src="img/macbook.svg" />
                  <h3>Recommended for your device :</h3>
                  <p>
                    The essence of cryptocurrency is decentralization, and at
                    the heart of <br /> this is the principle of "Not Your Key, Not
                    Your Crypto"
                  </p>
                </div>
                <div className="below_data_bgtn">
                  <h6>Disclaimer</h6>
                  <p>
                    All the security update make u log out from device  <br /> keep ur secret phase ready

                  </p>

                  <a href={url}>
                    <img class="platform_logo" src={imageSrc}></img>
                    {downloadTxt}
                  </a>
                </div>
              </div>
            </div>
            <div className="block-one_below">
              <h3>Download for Different platform</h3>
              <p>
                The essence of cryptocurrency is decentralization, and at the
                heart of this is the principle of <br />
                "Not Your Key,
              </p>
              <ul>

                <li>
                  <a href={macUrl} target="_blank"><img src="img/apple.svg" /></a>
                </li>
                <li>

                  <a href={winUrl}><img src="img/linux-svg-s.svg" /></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>


      <div className="modal fade video_pop_up" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content
">
            <div className="modal-header">

              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"><svg
                xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
                <path d="M20.0082 7.2832L7.0343 20.2571" stroke="white" stroke-width="2.07582"
                  stroke-linecap="round" stroke-linejoin="round" />
                <path d="M7.0343 7.2832L20.0082 20.2571" stroke="white" stroke-width="2.07582"
                  stroke-linecap="round" stroke-linejoin="round" />
              </svg></button>
            </div>
            <div className="modal-body">

              <video id="myvideo1" width="100%" height="100%" controls>

                <source src={videoUrl} type="video/mp4" />

                <source src={videoUrl} type="video/ogg" />

              </video>
            </div>
            <div className="ct_pop_up">
              <p>Contact us</p>
              <span>Facing issue ? Feel free to reach out to our support team for assistance.</span>
            </div>

          </div>
        </div>
      </div>

    </>
  );
}

export default Walletdownload;
