import React from "react";
import { useEffect } from "react";
import "../style-lg.css";

function Privacy() {
  return (
    <>
      <section class="privacy_page">
        <div class="main-container">
          <div class="inner-row">
            <div class="inner-block">
              <h1>CIFDAQ Wallet Privacy and Policy</h1>

              <h3>Last updated: 12 January 2024</h3>
              <p>
                This Privacy Policy (this “Privacy Policy”) describes how
                Shrisaya Wallet LLC and its affiliates (“CIFDAQ”) collect, use,
                and disclose information about you in connection with your use
                of the Services and access to the Site, including when you use
                or interact with the Services or Site, post on any community
                feature, register for any event or promotional campaign,
                complete any survey or questionnaire or otherwise communicate
                with us through any channel (including but not limited to our
                social media or contact information provided below). Unless
                specifically defined in this Privacy Policy, all capitalized
                terms have the meaning given to them in the Terms of Use. This
                Privacy Policy does not apply to any products, services,
                websites, or content that are offered by third parties or have
                their own privacy policy (whether or not affiliated with
                CIFDAQ). Please note other CIFDAQ Services or Site or Content
                may also have their own additional Privacy Policy or Terms of
                Use.
                <br />
                <br />
                Please read this Privacy Policy carefully so you understand how
                we handle information about you. We may update this Privacy
                Policy from time to time. If we make any changes, we will change
                the Last Updated date above. We encourage you to periodically
                review the Privacy Policy for the latest information on our
                privacy practices. Any modifications to this Privacy Policy will
                be effective upon our posting of the updated terms. In all
                cases, your continued use of the Services or access to the Site
                following the posting of any updated Privacy Policy indicates
                your acceptance of the updated Privacy Policy
              </p>
              <br />
              <br />
              <h2>1. What Information Do We Collect? </h2>
              <p>
                1.1. Information You Share With Us You may choose to give us
                information in various situations such as filling out a form,
                participating in an event or activity, raising support request,
                giving us feedback, signing up for email alerts, newsletters,
                etc. The type of information you may choose to give us includes:
                <br />
                <br />
                a. Identity Information: This includes your name, profile
                picture or other identifiers. <br />
                <br />
                b. Contact Details: This includes your email and phone number.{" "}
                <br />
                <br />
                c. Personal Communications: This includes your feedback,
                opinions, communication you send to us for support request, etc.{" "}
                <br />
                <br />
                d. Marketing Communications: This includes your preferences for
                receiving marketing communications from us and how you interact
                with our marketing content.
                <br />
                <br />
                <strong>1.2. Information We Collect Automatically </strong>
                We try our best to limit the information we collect about you
                automatically; however, we may collect certain types of data
                when you interact with our Services or Site, including but not
                limited to:
                <br />
                <br />
                a. Device Data: This includes data such as browser type, device
                type, operating system, etc.
                <br />
                <br />
                b. Blockchain Data: This includes public blockchain data
                including blockchain addresses and crypto asset transaction
                information.
                <br />
                <br />
                c. Aggregate Data: This includes analytics about number of users
                and types and information such as how many users in the
                aggregate are using certain protocols with assistance of
                Services; and location of users such as countries and regions
                from which users access the Services or the Site.
                <br />
                <br />
                <strong>1.3. Information Collected via Third Parties</strong>
                We do not collect any information via third parties
              </p>

              <h2>2. Do We Collect Any Child’s Data? </h2>
              <p>
                Our services are expressly for use for people above the age of
                18 or majority in their jurisdiction.
              </p>

              <h2>3. How Do We Use That Information? </h2>
              <p>
                3.1. Quality Assurance Purposes: We may use this data to
                personalise your use of the site or service, improve on our
                services, provide support, and resolve issues, etc.
                <br />
                <br />
                3.2. Prevent or Detect Violation of Policies and or Comply with
                Law: We may use this data to analyse user behaviour to prevent,
                detect or investigate any conduct that may be in violation of
                our policies or in order to comply with law, if we have a legal
                obligation to collect, use or retain information about you or
                comply with legal requests like subpoenas or requests from
                government authorities.
                <br />
                <br />
                3.3. Marketing: We may use this information to analyse the
                effectiveness of marketing or promotional strategies and market
                products, services, or campaigns.
                <br />
                <br />
                We may share this information with our sister companies or
                affiliates for use in our other services. We may also share
                information with our back servers or service providers like AWS
                and MoongDB. For more information on the kind of data collected
                by these platforms please refer to their privacy policies.
              </p>

              <h2>4. Your Control on Your Data (Access and Deletion)</h2>
              <p>
                If you need to update or delete certain data about you or your
                interaction with the Services, you can contact us.
                <br /> <br />
                You also have the choice of collection and use of information.
                You can choose to not provide certain information but that may
                affect your use of Services or access to certain features of the
                Site.
              </p>
              <h2>5. Location of Data</h2>
              <p>
                CIFDAQ has affiliate companies located throughout the world.
                Depending on the scope of your interactions with us, information
                about you, including personal information, may be stored in or
                accessed from multiple countries. Whenever we transfer personal
                information to other jurisdictions, we will ensure that the
                information is transferred in accordance with this Privacy
                Policy and as permitted by applicable data protection laws..
              </p>

              {/* <div class="inner_flex_bx">
                <div class="block-text">
                  <h3>Category of personal information</h3>
                  <p>
                    Personal details, contact details, and identifiers. System
                    and application access data and internet and electronic
                    network activity information
                  </p>
                </div>
                <div class="block-text">
                  <h3>Types of personal information captured by category</h3>
                  <p>
                    We collect your name, contact details (e.g., email, phone
                    numbers), IP address, CIFDAQ Wallet Service metrics (e.g.,
                    technical errors, interactions, preferences), and records of
                    canter
                  </p>
                </div>
                <div class="block-text">
                  <h3>Purpose</h3>
                  <p>
                    We collect and use your personal information to facilitate
                    customer support communication, provide troubleshooting
                    assistance, improve CIFDAQ Wallet services, analyze
                    performance, fix errors, enhance usability, and ensure the
                    effectiveness of our services. These activities are
                    necessary for fulfilling our contractual obligations to you.
                  </p>
                </div>
                <div class="block-text">
                  <h3>Legal Basis (UK & GDPR)</h3>
                  <p>
                    We process your personal information based on our legitimate
                    interests, which include ensuring proper communication and
                    handling within our organization. In certain cases, we may
                    rely on your consent to process your personal information
                    for specific purposes, and you have the right to withdraw
                    your consent at any time. We also have a legitimate
                    interest, as well as the interests of our users, in
                    detecting and preventing fraud and abuse to protect the
                    security of all parties involved. Additionally, we keep
                    records as necessary for fulfilling our contractual
                    obligations.
                  </p>
                </div>
              </div> */}
              <h2>6. How long Is your data retained? </h2>
              <p>
                We retain any data collected by us under this Policy for as long
                as it is necessary to fulfil the relevant purposes described in
                this Privacy Policy, unless a longer retention period is
                required or permitted by law such as for tax, accounting, fraud
                prevention, investigative, and dispute resolution purposes, or
                to establish or defend a dispute. In some instances, we may
                anonymise personal information about you such that it can no
                longer be used to identify you, in which case we can use such
                information indefinitely without further notice to you.
              </p>
              <h2>7. Contact Us, Notices, and Revisions </h2>
              <p>
                If you have any questions about this Privacy Policy, please
                Contact Us at: 74 E Glenwood Ave Unit #5334 Smyrna, Delaware
                19977 Kent, Delaware, USA or at ,{" "}
                <a href="mailto:legal@cifdaqwallet.com">
                  {" "}
                  legal@cifdaqwallet.com
                </a>
              </p>
              <h2>8. Additional Information For Specific Jurisdictions </h2>
              <p>
                <b> 8.1. If You Are a resident of EU, UK, or Switzerland</b>
                <br />
                If you are a data subject in the European Economic Area, the
                United Kingdom, or Switzerland, you have certain rights with
                respect to your personal data pursuant to the General Data
                Protection Regulation of the European Union (“GDPR”) and similar
                laws. This section applies to you.
                <br />
                <br />
                Any reference to “personal information” in this Privacy Policy
                is equivalent to “personal data” as defined under GDPR. CIFDAQ
                is the controller of your personal information as covered by
                this Privacy Policy for purposes of GDPR. Our address is as
                follows: 74 E Glenwood Ave Unit #5334 Smyrna, Delaware 19977
                Kent, Delaware, USA.
                <br />
                <br />
                We process your personal data in reliance on the legal bases
                below. Where the purpose of processing is:
                <br />
                <br />
                To provide our Services and Site and Improve Them: We process
                your personal data as necessary to perform the contract under
                which we provide our Services to you or to allow you to access
                our Site.
                <br />
                <br />
                To Comply With The Law and to prevent fraud, and ensure
                compliance: We process your personal data as necessary to comply
                with applicable laws and our legal obligations. We have a
                legitimate interest in processing your personal data, as
                described in this Privacy Policy, and our reasons for doing so
                outweigh any prejudice to your data protection rights. We also
                process your personal data as necessary to comply with our legal
                obligations.
                <br />
                <br />
                For Marketing Communications; Or For Research And Analytics: We
                have a legitimate interest in processing your personal data, as
                described in this Privacy Policy, and our reasons for doing so
                outweigh any prejudice to your data protection rights.
                <br />
                <br />
                With Your Consent: We process certain of your personal data with
                your consent. You may withdraw your consent at any time in the
                manner indicated when you provided consent to the collection of
                your personal data through your interactions with the Services
                or the Site.
                <br />
                <br />
                <b>Your rights:</b>
                <br />
                You may: (i) ask whether we have any personal data about you and
                request a copy of such personal data; (ii) request that we
                update or correct inaccuracies in your personal data; (iii)
                request that we delete your personal data; (iv) request a
                portable copy of your personal data; (v) request that we
                restrict the processing of your personal data if such processing
                is inappropriate; and (vi) object to our processing of your
                personal data. These rights are subject to applicable law.{" "}
                <br /> <br />
                If you would like to exercise any of these rights, please
                contact us (see “Contact Us” section above). To protect your
                information, we may need to verify your identity before
                processing your request, including by collecting additional
                information to verify your identity, such as government issued
                identification documents. <br /> <br />
                If you would like to submit a complaint about our use of your
                personal data or our response to your requests regarding your
                personal data, please Contact Us above. You may also lodge a
                complaint with your local data protection authority. <br />{" "}
                <br />
                If we transfer your personal data outside the European Economic
                Area, we will do so in accordance with the terms of this Privacy
                Policy and applicable data protection law.
                <br />
                <br />
                <b>8.2. If You Are a resident of California</b>
                <br />
                If you are a California resident, you have certain additional
                rights with respect to personal information about you under the
                California Consumer Privacy Act of 2018 (“CCPA”).
                <br />
                <br />
                We are required to inform you of:
                <br />
                <br />
                What categories of information we may collect about you,
                including during the preceding 12 months: See the section “What
                information do we receive from you or collect.” The purposes for
                which we may use your personal information and the purpose for
                which we share your personal information: including during the
                preceding 12 months See the section “How we use information.”
                <br />
                <br />
                We do not sell any personal information of consumers.
                <br />
                <br />
                <b>Your rights</b>
                <br />
                You have the right to request to know: (i) the categories of
                personal information we have collected about you in the last 12
                months; (ii) the specific pieces of personal information we have
                about you; (iii) the categories of sources from which that
                personal information was collected; (iv) the categories of your
                personal information that we sold or disclosed in the last 12
                months, if applicable; (v) the categories of third parties to
                whom your personal information was sold or disclosed in the last
                12 months; and (vi) the purpose for collecting and selling your
                personal information, if applicable. These rights are subject to
                limitations as described in the relevant law. We may deny your
                request if we need to do so to comply with our legal rights or
                obligations.
                <br />
                <br />
                <b>
                  We will not discriminate against any user for exercising their
                  CCPA rights.
                </b>
                <br />
                You may exercise these rights yourself or you may designate an
                authorised agent to make these requests on your behalf. To
                protect your information, we may need to verify your identity
                before processing your request, including by collecting
                additional information to verify your identity, such as
                government issued identification documents. We will not fulfil
                your request unless you have provided sufficient information for
                us to reasonably verify you are the individual about whom we
                collected personal information. We will only use the personal
                information provided in the verification process to verify your
                identity or authority to make a request and to track and
                document request responses unless you initially provided the
                information for another purpose. When we verify your agent’s
                request, we may verify your identity and request a signed
                document from your agent that authorizes your agent to make the
                request on your behalf. To protect your personal information, we
                reserve the right to deny a request from an agent who does not
                submit proof that they have been authorized by you to act on
                their behalf.
                <br />
                <br />
                If you would like to exercise any of these rights, please
                contact us at
                <a href="mailto:legal@cifdaqwallet.com">
                  {" "}
                  legal@cifdaqwallet.com
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Privacy;
