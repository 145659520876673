import React, { useEffect, useState } from "react";
import axios from 'axios';
import { getPlatform } from 'react-use-platform'

const DownloadButton = () => {

    const [url, setUrl] = useState('');
    let platform = getPlatform();
    let apiUrl = '';
    let downloadTxt = 'Download Wallet';
    let imageSrc = '';
    let videoUrl = '';
    if (platform == 'windows') {
        apiUrl = 'https://api.github.com/repos/Shrisaya/win-cifdaq-wallet/releases/latest';
        imageSrc = "img/window.svg";
        videoUrl = "../img/download_wallet_window.mp4";
        // imageSrc = "img/ios_mobile.svg";
        const getResult = async () => {
            try {
                const response = await axios.get(apiUrl);
                setUrl(response.data.assets[0].browser_download_url);
                //setIsLoading(false);
            } catch (error) {
                console.log(error);
            } finally {

            }
        }
        getResult();
    }
    if (platform == 'mac') {
        apiUrl = 'https://api.github.com/repos/Shrisaya/mac-cifdaq-wallet/releases/latest';
        imageSrc = "img/mac.svg";
        videoUrl = "../img/download_wallet_ios.mp4";
        const getResult = async () => {
            try {
                const response = await axios.get(apiUrl);
                setUrl(response.data.assets[2].browser_download_url);
            } catch (error) {
                console.log(error);
            } finally {

            }
        }
        getResult();
    }

    if (platform == 'android') {
        downloadTxt = 'Coming Soon';
        imageSrc = "img/android_mobile.svg";
    }

    if (platform == 'ios') {
        downloadTxt = 'Coming Soon';
        imageSrc = "img/ios_mobile.svg";
    }
    if (platform == 'unknown') {
        downloadTxt = 'Coming Soon';
        imageSrc = "/img/window.svg";
    }
    return (

        <>


            <a href={url} target="_blank"><img src={imageSrc}></img>  {downloadTxt}</a>


        </>
    )


}

export default DownloadButton