import React from "react";
import { useEffect } from "react";
import "../style-lg.css";

function Thankyou() {
  return (
    <>
      <section className="privacy_page">
        <div className="main-container">
          <div className="inner-row">
            <div className="inner-block">
              <div className="content">
                <div className="wrapper-1">
                  <div className="wrapper-2">
                    <h1>Request received !</h1>
                    <p>We have received your requst for Wallet deletion. </p>
                    <p>you will receive a confirmation email soon </p>
                    
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Thankyou;
