import React, { useState, useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { Col, Button, Row, Container, Card, Form } from "react-bootstrap";
import { Helmet } from "react-helmet";
import API from "../services/Api";
import { Link } from "react-router-dom";
function DeleteWalletComponent() {
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: { fullName: "", mobile: "", email: "", uniqueId: "", source: "CIFDAQ Wallet" },
  });

  useEffect(() => {
    // const name = websiteName();
    // document.title = "Contact Us | " + name;
  }, []);

  const showSuccessAlert = () => {
    Swal.fire({
      title: "Success",
      text: "Sucessfully sent",
      icon: "success",
      confirmButtonText: "OK",
    });
  };

  const showErrorAlert = () => {
    Swal.fire({
      title: "Error",
      text: "Something went wrong. Plase try again later",
      icon: "error",
      confirmButtonText: "OK",
    });
  };

  const onSubmit = async (data) => {
    //console.log(data);
    try {
      setIsLoading(true);
      const response = await API.post("email/walletDelete", { data });
      if (response.data.status == true) {
        showSuccessAlert();
        // reset({ ...data })
        reset({ fullName: "", mobile: "", email: "", uniqueId: "", source: "CIFDAQ Wallet" });
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
      showErrorAlert();
    }
  };

  return (
    <>
      <Helmet>
        <title>Wallet Delete Request Form | CIFDAQ Wallet</title>
        <meta name="description" content="Contact the CIFDAQ Wallet support staff for help, questions, and answers, Our top objective is to ensure a seamless wallet experience" />
        <meta name="keywords" content="CIFDAQ Wallet contact us, Crypto wallet, cryptocurrency wallet, digital wallet, best crypto wallet, safest crypto wallet" />

      </Helmet>
      <div className="vh-10 d-flex justify-content-center align-items-center contactus">

        <Col md={7} lg={7} xs={10} className="form_block">
          <Card className="px-4 block_card">
            <h1> CIFDAQ Wallet Delete Request Form  </h1>
            <Card.Body>
              <div className="mb-2 mt-md-4">
                <div className="mb-3">
                  <Form.Control type="hidden" name="source" id="source" value="CIFDAQ Wallet" />
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group className="mb-3" controlId="Name">
                      <Form.Control
                        type="text"
                        name="fullName"
                        placeholder="Name"
                        {...register("fullName", {
                          required: true,
                          pattern: /^[a-zA-Z\s]*$/,
                        })}
                      />
                      {errors.fullName &&
                        errors.fullName.type === "required" && (
                          <p className="errorMsg">Please enter your name.</p>
                        )}

                      {errors.fullName &&
                        errors.fullName.type === "pattern" && (
                          <p className="errorMsg">Invalid input.</p>
                        )}
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="Mobile">
                      <Form.Control
                        type="text"
                        name="mobile"
                        placeholder="Mobile Number"
                        {...register("mobile", {
                          required: true,
                          pattern: /^(0|[1-9]\d*)(\.\d+)?$/,
                          // minLength: 10,
                          // maxLength: 10,
                        })}
                      />
                      {errors.mobile && errors.mobile.type === "required" && (
                        <p className="errorMsg">Plese enter your mobile no.</p>
                      )}
                      {errors.mobile && errors.mobile.type === "pattern" && (
                        <p className="errorMsg">Invalid input.</p>
                      )}
                      {/* {errors.mobile && errors.mobile.type === "minLength" && (
                        <p className="errorMsg">
                          Mobile no should be at-least 10 digit.
                        </p>
                      )}
                      {errors.mobile && errors.mobile.type === "maxLength" && (
                        <p className="errorMsg">
                          Mobile no should be at-least 10 digit.
                        </p>
                      )} */}
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="email">
                      <Form.Control
                        type="email"
                        name="email"
                        placeholder="Email"
                        {...register("email", {
                          required: true,
                          pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                        })}
                      />
                      {errors.email && errors.email.type === "required" && (
                        <p className="errorMsg">Please enter your email.</p>
                      )}
                      {errors.email && errors.email.type === "pattern" && (
                        <p className="errorMsg">Email is not valid.</p>
                      )}
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="uniqueId">
                      <Form.Control
                        type="text"
                        name="uniqueId"
                        placeholder="Unique Id"
                        {...register("uniqueId", {
                          required: true,
                        })}
                      />
                      {errors.uniqueId && errors.uniqueId.type === "required" && (
                        <p className="errorMsg">Please enter unique id.</p>
                      )}
                    </Form.Group>
                    {isLoading && (
                      <img
                        src="img/loader.gif"
                        style={{
                          width: 35 + "px",
                          margin: "auto",
                          display: "block",
                          alt: "Loading",
                        }}
                      />
                    )}
                    {isLoading == false && (
                      <div className="d-grid mb-9">
                        <Button
                          className="mb-3  sbmit"
                          variant="success"
                          type="submit"
                        >
                          Submit
                        </Button>
                      </div>
                    )}
                  </Form>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </div>
    </>
  );
}

export default DeleteWalletComponent;
