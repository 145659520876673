import React, { useState, useCallback } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { Col, Button, Row, Container, Card, Form } from "react-bootstrap";
import API from "../services/Api";
import axios from "axios";
function NewsLetter() {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ defaultValues: { email: "", website: "CIFDAQ Wallet" } });

  const [allValid, setAllValid] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const showSuccessAlert = () => {
    Swal.fire({
      title: "Success",
      text: "Your email is subscribed successfully",
      icon: "success",
      confirmButtonColor: "#0F9D5C",
      confirmButtonText: "OK",
    });
  };

  const showAlready = () => {
    Swal.fire({
      title: "Already subscribed",
      text: "You're already part of the CIFDAQ Family. No need to subscribe twice. Stay tuned for awesome updates!",
      icon: "success",
      confirmButtonColor: "#0F9D5C",
      confirmButtonText: "OK",
    });
  };

  const showErrorAlert = () => {
    Swal.fire({
      title: "Error",
      text: "Something went wrong. Plase try again later",
      icon: "error",
      confirmButtonText: "OK",
      confirmButtonColor: "#ff0000",
    });
  };

  const onSubmit = async (data) => {
    try {
      setAllValid(false);
      setIsLoading(true);
      const response = await axios.post(
        process.env.REACT_APP_NEWSLETTER_API,
        data
      );
      if (response.data.subscribed) {
        return showAlready();
      }
      if (response.data.success == true) {
        Swal.fire({
          title: "Success",
          text: "Your email is subscribed successfully",
          icon: "success",
          confirmButtonColor: "#0F9D5C",

          confirmButtonText: "OK",
        });
        reset({ ...data });
        reset({ email: "", website: "CIFDAQ Wallet" });

        setAllValid(true);
        setIsLoading(false);
        // showSuccessAlert(true);
      }
    } catch (err) {
      showErrorAlert();
      setAllValid(true);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group className="" controlId="email">
          <div className="">
            <div className="subscribe_bar">
              <div className="input-group1">
                <Form.Control
                  type="hidden"
                  name="website"
                  value="CIFDAQ Wallet"
                />
                <Form.Control
                  type="email"
                  name="email"
                  placeholder="Your Email"
                  disabled={!allValid}
                  {...register("email", {
                    required: true,
                    pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                  })}
                />

                {isLoading && (
                  <img
                    className="image_loader"
                    src="img/loader.gif"
                    style={{ margin: "auto", display: "block", alt: "Loading" }}
                  />
                )}
                {isLoading == false && (
                  <button className="btn btn-default" type="submit">
                    Send
                  </button>
                )}
              </div>
              {errors.email && errors.email.type === "required" && (
                <p className="errorMsg">Please enter your email.</p>
              )}
              {errors.email && errors.email.type === "pattern" && (
                <p className="errorMsg">Email is not valid.</p>
              )}
            </div>
          </div>
        </Form.Group>
      </Form>
    </>
  );
}

export default NewsLetter;
