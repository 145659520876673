import React from "react";
import { Link, useLocation } from "react-router-dom";

function NavBar() {
  const location = useLocation();

  function refreshPage() {
    setTimeout(() => {
      window.location.reload(false);
    }, 10);
    console.log("page to reload");
  }
  return (
    <>
      <section className="sticky_header">
        <div className="header">
          <nav className="navbar navbar-expand-lg" aria-label="">
            <div className="container-nav">
              <Link
                to="/"
                onClick={refreshPage}
                className="navbar-brand header-home logo_h"
              >
                <img src="\img\logo.svg" />
              </Link>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#mainMenu"
                aria-controls="mainMenu"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <a
                href="https://linktr.ee/cifdaq "
                target="_blank"
                className=" hd-dkt migrate_btn"
              >
                LinkTree
                <span className="clr-yl ms-1"></span>
              </a>
              <div className="nav_wraper">
                <div
                  className="collapse navbar-collapse justify-content-md-center hide_menua"
                  id="mainMenu"
                ></div>
                <div
                  className="collapse navbar-collapse justify-content-md-end"
                  id="mainMenu"
                >
                  <div className="d-lg-flex justify-content-lg-end mg_btn">
                    <a
                      href="https://linktr.ee/cifdaq "
                      target="_blank"
                      className="  migrate_btn"
                    >
                      LinkTree
                      <span className="clr-yl ms-1"></span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </section>
    </>
  );
}

export default NavBar;
